@import url("https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap");

:root {
	--colour-grey-primary: #000;
	--colour-grey-secondary: #1e1e1e;
	--colour-grey-tertiary: #a3a3a3;
	--colour-blue: #0058ff;
	--colour-blue-tint: #3379ff;

	--text-colour-primary: #e7e7f0;
	--text-colour-secondary: #f7f7fa;
	--text-colour-tertiary: #c8c8d6;
	--text-colour-blue: #0058ff;
	--text-colour-blue-tint: #9bbdff;

	--full-vh: 100vh;
	--max-width: 1500px;
	--page-padding: 24px;
}

::-webkit-scrollbar {
	width: calc(5px + (2 * 5px));
	background: var(--colour-grey-primary);
}

::-webkit-scrollbar-thumb {
	border: 5px solid var(--colour-grey-primary);
	background-clip: padding-box;
	border-radius: 9999px;
	background: var(--colour-grey-tertiary);
}

body {
	margin: 0;
	background: var(--colour-grey-primary);
	color: var(--text-colour-primary);
	font-family: "Public Sans", -apple-system, "Segoe UI", sans-serif;
	font-family: "Muli", -apple-system, "Segoe UI", sans-serif;
	font-family: "Inter", -apple-system, "Segoe UI", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

button,
button:focus {
	font-family: "Public Sans", -apple-system, "Segoe UI", sans-serif;
	font-family: "Muli", -apple-system, "Segoe UI", sans-serif;
	font-family: "Inter", -apple-system, "Segoe UI", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border: 0;
	outline: 0;
}

.App {
	margin: 0;
	padding: 0;
	width: 100vw;
	min-height: 100vh;
}

@media (max-width: 1200px) {
	.App {
		overflow-x: hidden;
	}
}
